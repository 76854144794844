
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import { RequestPaging } from '@/utils/util'
import { apiRegionAgentWithdraw, apiRegionAgentWithdrawInfo, apiRegionAgentWithdrawList } from '@/api/finance'
import LsDialog from '@/components/ls-dialog.vue'
import DatePicker from '@/components/date-picker.vue'
import { Message } from 'element-ui'

@Component({
  components: {
    DatePicker,
    lsPagination,
    LsDialog
  }
})
export default class RegionAgentWithdrawList extends Vue {
  /** S Data **/
  pager: RequestPaging = new RequestPaging()

  pickerOptions = {
    shortcuts: [
      {
        text: '最近一周',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近一个月',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近三个月',
        onClick (picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          picker.$emit('pick', [start, end])
        }
      }
    ]
  }

  // 代理信息
  regionAgentInfo = {
    id: 0,
    name: ''
  }

  withdrawForm = {
    region_agent_id: '',
    apply_money: '',
    into_money: '',
    ratio: '',
    withdraw_type: 1,
    time: 0,
    real_name: '',
    alipay: '',
    reserve_mobile: '',
    card: '',
    bank: '',
    note: ''
  }

  // 弹窗状态
  visible = {
    val: false
  }

  form = {
    region_agent_id: 0,
    real_name: '',
    alipay: '',
    create_start_time: 0,
    create_end_time: 0
  }

  extendInfo: any = {
    agent_money: '',
    agent_withdrawal_money: ''
  }

  // 日期选择器数据
  timeForm = []

  /** E Data **/

  /** S Methods **/

  onSubmit () {
    apiRegionAgentWithdraw(this.withdrawForm).then(res => {
      if (res.code === 1) {
        Message({ type: 'error', message: res.msg })
      } else {
        Message({ type: 'success', message: res.msg })
        this.query()
        this.visible.val = false
      }
    })
  }

  withdrawAction () {
    apiRegionAgentWithdrawInfo({ region_agent_id: this.regionAgentInfo.id }).then(res => {
      const { ratio, withdrawType, realName, alipay, reserveMobile, card, bank } = res
      this.withdrawForm.ratio = ratio
      this.withdrawForm.withdraw_type = withdrawType
      this.withdrawForm.real_name = realName
      this.withdrawForm.alipay = alipay
      this.withdrawForm.reserve_mobile = reserveMobile
      this.withdrawForm.card = card
      this.withdrawForm.bank = bank
      this.visible.val = true
    })
  }

  handleClose () {
    this.visible.val = false
  }

  // 拆分日期选择器时间
  splitTime () {
    if (this.timeForm != null) {
      this.form.create_start_time = new Date(this.timeForm[0]).getTime() / 1000
      this.form.create_end_time = new Date(this.timeForm[1]).getTime() / 1000
    } else {
      this.form.create_start_time = 0
      this.form.create_end_time = 0
    }
  }

  onReset () {
    this.form = {
      region_agent_id: this.regionAgentInfo.id,
      real_name: '',
      alipay: '',
      create_start_time: 0,
      create_end_time: 0
    }
    this.timeForm = []
    this.pager.page = 1
    this.getListInit()
  }

  query () {
    this.pager.page = 1
    this.getListInit()
  }

  // 获取数据列表
  getListInit (): void {
    this.pager.request({
      callback: apiRegionAgentWithdrawList,
      params: {
        ...this.form
      }
    }).then(res => {
      this.extendInfo = res.extend
    })
  }

  /** E Methods **/

  /** S ods **/
  created () {
    const query: any = this.$route.query
    this.regionAgentInfo.id = query.id
    this.withdrawForm.region_agent_id = query.id
    this.form.region_agent_id = query.id
    this.regionAgentInfo.name = query.name
    this.withdrawForm.time = new Date(this.timeForm[0]).getTime() / 1000
    this.getListInit()
  }
}
